import './StackVisual.css';
import { StateExample } from './stackMission';


function Stack({state}: {state:StateExample}) {
  const stack = [...state.stack];
  return (<div className="stack-instance">{stack.map((slot, ix) =>
    <div key={ix} className='stack-element'>
      <span>{slot.address}</span>
      <span className="slot">{slot.value}</span>
      <span>{ slot.marker !== undefined && <>🠔 {slot.marker}</> }</span>
    </div> )}</div>);
}


export function StackExample(props: { examples: StateExample[]}) {
    return (<div>
        <div><b>Example</b></div>
        <StackVisual states={props.examples} /></div>);
}

export function StackVisual(props: {states: StateExample[]}) {
  const states = props.states.map((state, ix) =>
    <td key={ix}>
      <div className="state">
        <Stack state={state} />
      </div>
    </td>
  );
  return (<div className="stack-visual">
    <table className="states">
    <tbody>
    <tr>{props.states.map((st, ix) => <th key={ix}>{st.label}</th>)}</tr>
    <tr>{props.states.map((st, ix) =>
        <td key={ix}>{st.registers && <i>Registers</i>}</td>)}
    </tr>
    <tr>{props.states.map((st, ix) =>
        <td key={ix}>{st.registers && st.registers.map((r, ix) =>
          <div key={ix} className="register-example">{r.name}: {r.value}</div>)}</td>
      )}</tr>
    <tr>{props.states.map((st, ix) => <td key={ix}><i>Stack</i></td>)}</tr>
    <tr>{states}</tr>
    </tbody>
    </table>
  </div>);
}
