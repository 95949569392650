import { pureStackTest } from './codeTester';
import { StackMissionBase } from './stackMission';

export const stackEqMission = new class extends StackMissionBase {
    key = 'STACK_EQ';
    macro = { name: 'eq' };
    examples = [
        {label: 'before', stack: [{value:'7'}, {value:'8'}]},
        {label: 'after', stack: [{value:'0'}]},
    ];
    tests = [
        pureStackTest([9, 9], [0xFFFF]),
        pureStackTest([9, 8], [0]),
    ];
    defaultTestCode = `\
init.stack
push.value 7
push.value 7
eq
# inspect top of stack. Should be xFFFF.
`
};

export const stackGtMission = new class extends StackMissionBase {
    key = 'STACK_GT';
    macro = { name: 'gt' };
    examples = [
        {label: 'before', stack: [{value:'5'}, {value:'3'}]},
        {label: 'after', stack: [{value:'0'}]},
    ]
    tests =  [
        pureStackTest([15, 7], [0xFFFF]),
        pureStackTest([7, 15], [0]),
        pureStackTest([0xFFF4, 0xFFF4], [0]),
    ];
    defaultTestCode = `\
init.stack
push.value 8
push.value 7
gt
# inspect top of stack. Should be 0.
`
};

export const stackLtMission = new class extends StackMissionBase {
    key = 'STACK_LT';
    macro = { name: 'lt' };
    tests =  [
        pureStackTest([15, 7], [0]),
        pureStackTest([7, 15], [0xFFFF]),
        pureStackTest([0xFFF4, 0xFFF4], [0]),
    ];
    examples = [
        {label: 'before', stack: [{value:'5'}, {value:'3'}]},
        {label: 'after', stack: [{value:'FFFF'}]},
    ]
    defaultTestCode = `\
init.stack
push.value x0008
push.value x0005
lt
# inspect top of stack. Should be FFFF.
`
};
